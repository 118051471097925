/* Import Fonts */
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* CSS Variables for Theming */
:root {
  /* Colors */
  --primary: #B40F09;
  --secondary: #6c757d;
  --border: rgba(255, 255, 255, 0.2);
  --text-primary: #fff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --background-light: rgba(255, 255, 255, 0.1);
  --background-secondary: rgba(138, 123, 123, 0.34);
  --background-tertiary: rgba(30, 30, 30, 0.5);
  --success: #28a745;
  --warning: #ffc107;
  --danger: #dc3545;
  --info: #17a2b8;

  /* Text Colors */
  /* --text-primary: #FFFFFF; */
  /* --text-secondary: #CCCCCC; */
  /* --text-disabled: rgba(255, 255, 255, 0.5); */

  /* Background Colors */
  /* --background: #000000; */
  /* --background-card: rgba(138, 123, 123, 0.34); */
  /* --background-modal: rgba(19, 20, 23, 0.9); */

  /* Border Colors */
  /* --border: rgba(255, 255, 255, 0.1); */

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* Border Radius */
  --border-radius-sm: 5px;
  --border-radius-md: 10px;
  --border-radius-lg: 20px;

  /* Font Sizes */
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 18px;
  --font-size-xxl: 24px;
  --font-size-header: 28px;

  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.2);
  --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.3);

  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;

  /* Z-index levels */
  --z-index-dropdown: 10;
  --z-index-sticky: 20;
  --z-index-fixed: 30;
  --z-index-modal-backdrop: 40;
  --z-index-modal: 50;
  --z-index-popover: 60;
  --z-index-tooltip: 70;
}

/* Light theme overrides */
.light-theme {
  --primary: #B40F09;
  --secondary: #6c757d;
  --border: rgba(0, 0, 0, 0.2);
  --text-primary: #333;
  --text-secondary: rgba(0, 0, 0, 0.7);
  --background-light: rgba(0, 0, 0, 0.1);
  --background-secondary: rgba(240, 240, 240, 0.7);
  --background-tertiary: rgba(220, 220, 220, 0.7);
  --success: #28a745;
  --warning: #ffc107;
  --danger: #dc3545;
  --info: #17a2b8;
}

body {
  font-family: 'Play', sans-serif;
  background-color: var(--background);
  color: var(--text-primary);
  font-size: var(--font-size-md);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: var(--text-primary);
  text-decoration: none;
  transition: color var(--transition-fast);
}

a:hover {
  opacity: 0.8;
}

button, input, select, textarea {
  font-family: 'Play', sans-serif;
}

/* Toast Customization */
.Toastify__toast {
  border-radius: var(--border-radius-sm);
}

.Toastify__toast--dark {
  background-color: var(--background-modal);
  color: var(--text-primary);
}

.Toastify__toast--light {
  background-color: white;
  color: #333;
}

/* Scrollbar Customization */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(138, 123, 123, 0.6);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(138, 123, 123, 0.8);
}

/* Common Utility Classes */
.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: var(--spacing-xs);
}

.mt-2 {
  margin-top: var(--spacing-sm);
}

.mt-3 {
  margin-top: var(--spacing-md);
}

.mt-4 {
  margin-top: var(--spacing-lg);
}

.mt-5 {
  margin-top: var(--spacing-xl);
}

.mb-1 {
  margin-bottom: var(--spacing-xs);
}

.mb-2 {
  margin-bottom: var(--spacing-sm);
}

.mb-3 {
  margin-bottom: var(--spacing-md);
}

.mb-4 {
  margin-bottom: var(--spacing-lg);
}

.mb-5 {
  margin-bottom: var(--spacing-xl);
}

.p-1 {
  padding: var(--spacing-xs);
}

.p-2 {
  padding: var(--spacing-sm);
}

.p-3 {
  padding: var(--spacing-md);
}

.p-4 {
  padding: var(--spacing-lg);
}

.p-5 {
  padding: var(--spacing-xl);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: var(--spacing-xs);
}

.gap-2 {
  gap: var(--spacing-sm);
}

.gap-3 {
  gap: var(--spacing-md);
}

.w-full {
  width: 100%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

/* Responsive utilities */
@media (max-width: 767.98px) {
  .hidden-mobile {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden-tablet {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-desktop {
    display: none !important;
  }
}

/* Mobile optimization */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* Responsive typography */
html {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 12px;
  }
}

/* Mobile navigation improvements */
@media screen and (max-width: 768px) {
  .mobile-menu {
    flex-direction: column;
    width: 100%;
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-full-width {
    width: 100% !important;
    max-width: 100% !important;
  }

  .mobile-stack {
    display: flex !important;
    flex-direction: column !important;
  }

  .mobile-center {
    text-align: center !important;
    justify-content: center !important;
  }

  .mobile-padding {
    padding: 10px !important;
  }

  .mobile-margin {
    margin: 10px 0 !important;
  }
}

/* Touch-friendly interactions */
@media (hover: none) {
  /* Enlarge tap targets for touch devices */
  button,
  .button,
  input[type="button"],
  input[type="submit"] {
    min-height: 44px;
    min-width: 44px;
    padding: 10px 15px;
  }

  /* Increase spacing between interactive elements */
  a, button, input, select, textarea {
    margin: 8px 0;
  }
}

/* Print optimization */
@media print {
  body {
    background: none !important;
    color: #000 !important;
  }

  .no-print {
    display: none !important;
  }
}

/* Accessibility improvements */
:focus {
  outline: 2px solid var(--primary) !important;
  outline-offset: 2px !important;
}

/* Display orientation handling */
@media screen and (orientation: portrait) {
  .landscape-only {
    display: none !important;
  }
}

@media screen and (orientation: landscape) {
  .portrait-only {
    display: none !important;
  }
}
