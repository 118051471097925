.Mui-disabled {
  color: #FFF !important;
  -webkit-text-fill-color: #fff !important;
}
::-webkit-scrollbar {
  width: 1em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #8A7B7B;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
